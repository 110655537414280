<template>
  <firm-dgi-form v-model="obFirm" />
</template>

<script lang="ts">
import type { FirmData } from "@planetadeleste/vue-mc-gw";
import { Company } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel } from "vue-property-decorator";
import FirmDgiForm from "@/modules/companies/components/FirmDgiForm.vue";

@Component({ components: { FirmDgiForm } })
export default class CompanyDgi extends Vue {
  @VModel({ type: Object, default: () => new Company() })
  obCompany!: Company;

  get obFirm() {
    return this.obCompany.firm || {};
  }

  set obFirm(obData: Partial<FirmData>) {
    this.obCompany.set("firm", obData);
  }
}
</script>
